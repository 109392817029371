export default [
    {
        path: '/pages/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/pages/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: '*',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: '*',
        },
    },
    {
        path: '/requests/to-analyze',
        name: 'requests',
        component: () => import('@/views/requests/list/List.vue'),
        props: { url: '/solicitation/to-analysis' },
        meta: {
            resource: '*',
            breadcrumb: [
              { text: 'Requests To Review', active: true },
            ],
            reload: true,
        },
    },
    {
        path: '/requests/history',
        name: 'requests-history',
        component: () => import('@/views/requests/list/List.vue'),
        props: { url: '/solicitation/requests-history' },
        meta: {
            resource: '*',
            breadcrumb: [
              { text: 'Requests History', active: true },
            ],
            reload: true,
        },
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/contacts/ListContacts.vue'),
        meta: {
            resource: 'importation',
            action: 'quotation',
            breadcrumb: [
              { text: 'Contacts', active: true },
            ],
            reload: true,
        },
    }
]
