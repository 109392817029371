export default [
    {
        path: '/dashboard/admin',
        name: 'dashboard-admin',
        component: () => import('@/views/Home.vue'),
        meta: {
            resource: 'dashboard',
            action: 'read',
            pageTitle: 'Home',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/dashboard/user',
        name: 'dashboard-user',
        component: () => import('@/views/Home.vue'),
        meta: {
            resource: '*',
            pageTitle: 'Home',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    }
]
