export default [
    {
        path: '/role/list',
        name: 'role-list',
        component: () => import('@/views/permissions/role/roleList.vue'),
        meta: {
            resource: 'role',
            action: 'read',
            pageTitle: 'Roles',
            breadcrumb: [
                { text: 'Roles', active: true }
            ],
        },
    },
    {
        path: '/permission/list',
        name: 'permission-list',
        component: () => import('@/views/permissions/permission/permissionList.vue'),
        meta: {
            resource: 'permission',
            action: 'read',
        },
    }
  ]
  