import Vue from 'vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.request.use(
  config => {
     config = {
      showToast: false, // may be overwritten in next line
      ...(config || {}),
    };
    return config
  }
)
axiosIns.interceptors.response.use(response => {
  // Qualquer código de status que dentro do limite de 2xx faz com que está função seja acionada
  // Faz alguma coisa com os dados de resposta
  if (response.config.showToast) {
    Vue.$toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: `Sucesso`,
        icon: "CheckIcon",
        variant: "success",
        text: response.data.message,
      },
    });
  }
  return response;
}, error => {
  // Qualquer código de status que não esteja no limite do código 2xx faz com que está função seja acionada
  // Faz alguma coisa com o erro da resposta
    let text
    if (error.response) {
      text = error.response.data.message
    }else{
      text = error.message
    }
    Vue.$toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: `Falha`,
        icon: "XIcon",
        variant: "danger",
        text,
      },
    });
  return Promise.reject(error);
});
Vue.prototype.$http = axiosIns

export default axiosIns
