export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/users-list/UsersList.vue'),
    meta: {
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'users',
      action: 'create',
    },
  },
]
