export default [
  {
    path: '/importation',
    name: 'importation',
    component: () => import('@/views/importation/solicitation/Index.vue'),
    redirect: { name: 'importation-list' },
    meta: {
      resource: 'importation',
      action: 'read',
      navActiveLink: 'importation',
    },
    children:[
      {
        path: 'requests',
        name: 'importation-list',
        component: () => import('@/views/importation/solicitation/list/List.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'requests/create/ge',
        name: 'importation-create-ge',
        component: () => import('@/views/importation/solicitation/forms/ge/FormGE.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
        },
      },
      {
        path: 'requests/edit/ge/:id',
        name: 'importation-edit-ge',
        component: () => import('@/views/importation/solicitation/forms/ge/FormGE.vue'),
        props: { mode: 'edit' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'update',
          pageTitle: 'Edit',
        },
      },
      {
        path: 'requests/show/ge/:id',
        name: 'importation-show-ge',
        component: () => import('@/views/importation/solicitation/forms/ge/ShowGE.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
          pageTitle: 'To View',
        },
      },
      {
        path: 'requests/create/ga',
        name: 'importation-create-ga',
        component: () => import('@/views/importation/solicitation/forms/ga/FormGA.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
          //pageTitle: 'GA-Solicitação de insumo',
        },
      },
      {
        path: 'requests/edit/ga/:id',
        name: 'importation-edit-ga',
        component: () => import('@/views/importation/solicitation/forms/ga/FormGA.vue'),
        props: { mode: 'edit' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
          //pageTitle: 'GA-Solicitação de insumo',
        },
      },
      {
        path: 'requests/show/ga/:id',
        name: 'importation-show-ga',
        component: () => import('@/views/importation/solicitation/forms/ga/ShowGA.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
          //pageTitle: 'GA-Solicitação de insumo',
        },
      },
      {
        path: 'requests/create/gd',
        name: 'importation-create-gd',
        component: () => import('@/views/importation/solicitation/forms/gd/FormGD.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
        },
      },
      {
        path: 'requests/edit/gd/:id',
        name: 'importation-edit-gd',
        component: () => import('@/views/importation/solicitation/forms/gd/FormGD.vue'),
        props: { mode: 'edit' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'update',
        },
      },
      {
        path: 'requests/show/gd/:id',
        name: 'importation-show-gd',
        component: () => import('@/views/importation/solicitation/forms/gd/ShowGD.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'requests/create/gb',
        name: 'importation-create-gb',
        component: () => import('@/views/importation/solicitation/forms/gb/FormGB.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'create',
          pageTitle: 'Create',
        },
      },
      {
        path: 'requests/edit/gb/:id',
        name: 'importation-edit-gb',
        component: () => import('@/views/importation/solicitation/forms/gb/FormGB.vue'),
        props: { mode: 'edit' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'update',
          pageTitle: 'Edit',
        },
      },
      {
        path: 'requests/show/gb/:id',
        name: 'importation-show-gb',
        component: () => import('@/views/importation/solicitation/forms/gb/ShowGB.vue'),
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
          pageTitle: 'To View',
        },
      },
      {
        path: 'requests/create/raw-material',
        name: 'importation-create-raw-material',
        component: () => import('@/views/importation/solicitation/forms/rawMaterial/FormRawMaterial.vue'),
        props: { mode: 'create' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'requests/edit/raw-material/:id',
        name: 'importation-edit-raw-material',
        component: () => import('@/views/importation/solicitation/forms/rawMaterial/FormRawMaterial.vue'),
        props: { mode: 'edit' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'requests/show/raw-material/:id',
        name: 'importation-show-raw-material',
        component: () => import('@/views/importation/solicitation/forms/rawMaterial/FormRawMaterial.vue'),
        props: { mode: 'show' },
        meta: {
          navActiveLink: 'importation-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport',
        name: 'transport-list',
        component: () => import('@/views/importation/transport/list/List.vue'),
        meta: {
          navActiveLink: 'importation',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/create/air',
        name: 'transport-create-air',
        component: () => import('@/views/importation/transport/forms/air/Create.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/edit/air/:id',
        name: 'transport-edit-air',
        component: () => import('@/views/importation/transport/forms/air/Edit.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/show/air/:id',
        name: 'transport-show-air',
        component: () => import('@/views/importation/transport/forms/air/Show.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/quotation/air/:id',
        name: 'transport-quotation-air',
        component: () => import('@/views/importation/transport/forms/air/Quotation.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'quotation',
        },
      },
      {
        path: 'transport/create/sea',
        name: 'transport-create-sea',
        component: () => import('@/views/importation/transport/forms/sea/Create.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/edit/sea/:id',
        name: 'transport-edit-sea',
        component: () => import('@/views/importation/transport/forms/sea/Edit.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
      {
        path: 'transport/show/sea/:id',
        name: 'transport-show-sea',
        component: () => import('@/views/importation/transport/forms/sea/Show.vue'),
        meta: {
          navActiveLink: 'transport-list',
          resource: 'importation',
          action: 'read',
        },
      },
    ]
  },

]
